import React from "react";

export default function PageNotFound() {
  return (
    <>
      <h1>Pagina niet gevonden</h1>
      <p>De pagina bestaat niet.</p>
    </>
  );
}
